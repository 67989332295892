import Image from "next/image";
import SelectMenu from "../components/SelectMenu";
import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBath,
  faBedFront,
  faPuzzlePiece,
  faRulerCombined,
  faStairs,
} from "@fortawesome/pro-solid-svg-icons";
import Link from "next/link";
import LikeButton from "./Like";
import { LoginContext, UserContext } from "./Layout";
import * as Sentry from "@sentry/nextjs";

//import fetch from "node-fetch";

export interface Model {
  id: number;
  name: string;
  desc: string;
  slug: string;
  garage: boolean | null;
  stories: number;
  sqft: number;
  bedrooms: number;
  bathrooms: number;
  active: number;
  sort_order: number;
  base_price: number;
  type: "string";
  images: string[];
  floorplans?: string[] | null;
  featured: number;
  footprint?: string;
  num_modules?: number;
  bathroom_upgrade?: number;
  palettes?: {
    interior: [string[]];
    exterior: [string[]];
  };
  lighting?: {
    islandPendants: [string[]];
    diningPendants: [string[]];
  };
  paletteThumbs?: {
    interior: [
      {
        palette: string;
        render: string;
        paletteName: string;
        palettePrice: number;
      }
    ];
    exterior: [
      {
        palette: string;
        render: string;
        paletteName: string;
        palettePrice: number;
      }
    ];
  };
  packages?: {
    Bathroom: {};
    "Bathroom Upgrade": {};
    Appliances: {};
    Stairs: {};
    "Kitchen Fixtures": {};
    "Laundry Fixtures": {};
    "Dining Pendants": {};
    "Island Pendants": {};
    "Standard Lighting": {};
    Fireplace: {};
  };
}

interface dataFromAPI {
  status: number;
  body: Model[];
}
interface fetchHeaders {
  accept: string;
  "x-api-key": string;
}
export const fetchModels = async () => {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    "x-api-key": process.env.NEXT_PUBLIC_AWS_API_KEY!,
  };

  const models = await fetch(process.env.NEXT_PUBLIC_AWS_BASE_URL + "models", {
    headers,
    method: "GET",
  });
  const result = await models.json();
  return result;
};

// fetch liked models
async function fetchLikedModels(uuid: string, userId: any) {
  var data = { uuid: uuid, user_id: userId, action: "get" };
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    "x-api-key": process.env.NEXT_PUBLIC_AWS_API_KEY!,
  };

  try {
    var response = await fetch(process.env.NEXT_PUBLIC_AWS_BASE_URL + "likes", {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    // console.log("result ", typeof (Object.values(JSON.parse(result.body))));
    return JSON.parse(result.body);
  } catch (error) {
    console.error("Error sending data:", error);
    if (error instanceof Error) {
      Sentry.captureException("Error fetching liked models: " + error.message);
    }
  }
}

const storyOptions = [
  {
    id: 1,
    value: "All",
  },
  {
    id: 2,
    value: "1",
  },
  {
    id: 3,
    value: "2",
  },
];

const squarefeetOptions = [
  {
    id: 1,
    value: "All",
  },
  {
    id: 2,
    value: "300 - 1000",
  },
  {
    id: 3,
    value: "1000 - 2000",
  },
  {
    id: 4,
    value: "2000 - 3000",
  },
];
const bdrmOptions = [
  {
    id: 1,
    value: "All",
  },
  {
    id: 2,
    value: "1",
  },
  {
    id: 3,
    value: "2",
  },
  {
    id: 4,
    value: "3",
  },
  {
    id: 5,
    value: "4",
  },
];
const bthrmOptions = [
  {
    id: 1,
    value: "All",
  },
  {
    id: 2,
    value: "1",
  },
  {
    id: 3,
    value: "2",
  },
  {
    id: 4,
    value: "2.5",
  },
  {
    id: 5,
    value: "3",
  },
  {
    id: 6,
    value: "3.5",
  },
  {
    id: 7,
    value: "4.5",
  },
];
const sortOptions = [
  {
    id: 1,
    value: "Price",
  },
  {
    id: 2,
    value: "Square Feet",
  },
  {
    id: 3,
    value: "Bedrooms",
  },
  {
    id: 4,
    value: "Bathrooms",
  },
  {
    id: 5,
    value: "Stories",
  },
];

interface FiltersProps {
  setFilteredModels: React.Dispatch<React.SetStateAction<Model[]>>;
  models: Model[];

  likedModels: number[];
}
const Filters = ({ setFilteredModels, models, likedModels }: FiltersProps) => {
  const [storyFilterVal, setStoryFilterVal] = useState("All");
  const [sqftFilterVal, setSqftFilterVal] = useState("All");
  const [bdrmFilterVal, setBdrmFilterVal] = useState("All");
  const [bthrmFilterVal, setBthrmFilterVal] = useState("All");
  const [sortVal, setSortVal] = useState("Price");

  useEffect(() => {
    if (!models) {
      return;
    }
    let filteredModels = models.filter((model) => {
      if (storyFilterVal == "All") {
        return true;
      }
      return storyFilterVal == model.stories.toString();
    });

    filteredModels = filteredModels.filter((model) => {
      if (sqftFilterVal == "All") {
        return true;
      }
      let lowerRange = parseInt(sqftFilterVal.split(" - ")[0]);
      let upperRange = parseInt(sqftFilterVal.split(" - ")[1]);
      return model.sqft >= lowerRange && model.sqft < upperRange;
    });

    filteredModels = filteredModels.filter((model) => {
      if (bdrmFilterVal == "All") {
        return true;
      }
      return bdrmFilterVal == model.bedrooms.toString();
    });

    filteredModels = filteredModels.filter((model) => {
      if (bthrmFilterVal == "All") {
        return true;
      }
      return bthrmFilterVal == model.bathrooms.toString();
    });

    filteredModels.sort((a, b) => {
      const aIndex = likedModels.indexOf(a.id);
      const bIndex = likedModels.indexOf(b.id);

      if (aIndex === -1 && bIndex === -1) {
        // if both items are not in the prioritizeItems array, maintain their order
        return filteredModels.indexOf(a) - filteredModels.indexOf(b);
      } else if (aIndex === -1) {
        // if only a is not in the prioritizeItems array, sort b first
        return 1;
      } else if (bIndex === -1) {
        // if only b is not in the prioritizeItems array, sort a first
        return -1;
      } else {
        // if both items are in the prioritizeItems array, maintain their relative order
        return aIndex - bIndex;
      }
    });
    filteredModels.sort((a, b) => {
      if (sortVal == "Stories") {
        return a.stories - b.stories;
      } else if (sortVal == "Square Feet") {
        return a.sqft - b.sqft;
      } else if (sortVal == "Bedrooms") {
        return a.bedrooms - b.bedrooms;
      } else if (sortVal == "Bathrooms") {
        return a.bathrooms - b.bathrooms;
      } else if (sortVal == "Price") {
        return a.base_price - b.base_price;
      }
      // return a.name.localeCompare(b.name);
      return a.active - b.active;
    });

    setFilteredModels(filteredModels);
  }, [
    storyFilterVal,
    sqftFilterVal,
    bdrmFilterVal,
    bthrmFilterVal,
    sortVal,
    models,
    setFilteredModels,
    likedModels,
  ]);

  return (
    <div className="flex lg:justify-between flex-wrap">
      <div className="flex flex-wrap">
        <div className="w-44 mr-6 mt-2 hidden lg:inline">
          <SelectMenu
            label="Stories"
            values={storyOptions}
            onChange={setStoryFilterVal}
          />
        </div>
        <div className="w-44 mt-2 mr-6 hidden lg:inline">
          <SelectMenu
            label="Sq. Ft."
            values={squarefeetOptions}
            onChange={setSqftFilterVal}
          />
        </div>
        <div className="w-44 mt-2 mr-6 hidden lg:inline">
          <SelectMenu
            label="Bedrooms"
            values={bdrmOptions}
            onChange={setBdrmFilterVal}
          />
        </div>
        <div className="w-44 mt-2 hidden lg:inline">
          <SelectMenu
            label="Bathrooms"
            values={bthrmOptions}
            onChange={setBthrmFilterVal}
          />
        </div>
      </div>
      <div className="w-full w-56 lg:w-56 mt-2">
        <SelectMenu
          label="Sort by"
          values={sortOptions}
          onChange={setSortVal}
        />
      </div>
    </div>
  );
};

interface ModelOverviewWidgetProps {
  model: Model;

  updateLikedModels: (newLikedModels: number[]) => void;
  likedModels: number[];
}
const ModelOverviewWidget = ({
  model,
  // setLikedModels,
  likedModels,
  updateLikedModels,
}: ModelOverviewWidgetProps) => {
  const mainImg = model.images[0];

  return (
    <motion.div layout className={"model-widget"}>
      <Link
        href={{
          pathname: `/design/${encodeURIComponent(model.slug)}`,
        }}
      >
        <div className="hover-animation">
          <div className="thumb-img-wrapper">
            <Image
              src={process.env.NEXT_PUBLIC_AWS_CDN_URL + mainImg}
              alt="Dvele Home model"
              width={300}
              height={200}
              className="w-full mb-2"
              placeholder="blur"
              blurDataURL="../public/assets/StockHome.png"
            />
          </div>

          <span>{model.name}</span>
          <div className="flex opacity-70 items-center mb-1 flex-wrap items-baseline">
            <div className="flex items-baseline">
              <FontAwesomeIcon
                icon={faStairs}
                style={{ fontSize: 14, marginRight: 4 }}
              />
              <p>{model.stories}</p>
              <span className="px-2">|</span>
            </div>
            <div className="flex items-baseline">
              <FontAwesomeIcon
                icon={faBedFront}
                style={{ fontSize: 14, marginRight: 4 }}
              />
              <p>{model.bedrooms}</p>
              <span className="px-2">|</span>
            </div>
            <div className="flex items-baseline">
              <FontAwesomeIcon
                icon={faBath}
                style={{ fontSize: 14, marginRight: 4 }}
              />
              <p>{model.bathrooms}</p>
              <span className="px-2">|</span>
            </div>
            <div className="flex items-baseline">
              <FontAwesomeIcon
                icon={faRulerCombined}
                style={{ fontSize: 14, marginRight: 4 }}
              />
              <p>{model.footprint}</p>
              <span className="px-2">|</span>
            </div>
            <div className="flex items-baseline">
              <FontAwesomeIcon
                icon={faPuzzlePiece}
                style={{ fontSize: 14, marginRight: 4 }}
              />
              <p>{model.num_modules}</p>
              <span className="px-2">|</span>
            </div>

            <p>{model.sqft} sq. ft.</p>
          </div>
          <div className="flex">
            <p className="flex-1">
              Starting at $
              {new Intl.NumberFormat("en-US", {
                maximumSignificantDigits: 4,
              }).format(model.base_price)}
            </p>

            <LikeButton
              likedModels={likedModels}
              updateLikedModels={updateLikedModels}
              model={model}
            />
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

interface ModelsProps {
  setFeaturedModels: React.Dispatch<React.SetStateAction<Model[]>>;
}
export default function Models({ setFeaturedModels }: ModelsProps) {
  const [models, setModels] = useState<Model[]>([]);
  const [filteredModels, setFilteredModels] = useState<Model[]>([]);
  const [likedModels, setLikedModels] = useState<number[]>([]);
  const userUUID = useContext(UserContext);
  const loggedInUser = useContext(LoginContext);

  function updateLikedModels(newLikedModels: number[]) {
    setLikedModels(newLikedModels);
  }

  useEffect(() => {
    fetchModels().then((data) => {
      setModels(data["body"]);
      setFilteredModels(data["body"]);
      let featured: Model[] = [];
      data["body"] &&
        data["body"].forEach((model: Model) => {
          if (model["featured"] != 0) {
            featured.push(model);
          }
        });
      setFeaturedModels(featured);
    });
  }, []);

  useEffect(() => {
    // console.log("uuid ", userUUID);
    fetchLikedModels(userUUID, loggedInUser).then((data) => {
      setLikedModels(data);
      localStorage.setItem("likedModels", JSON.stringify(data));
    });
  }, [userUUID, loggedInUser]);

  return (
    <>
      <div className="pb-8 px-10 pt-20">
        <h2>Models</h2>
        <Filters
          setFilteredModels={setFilteredModels}
          likedModels={likedModels}
          models={models}
        />

        <motion.div
          layout
          className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 gap-8 mt-4 md:mt-12"
        >
          {models &&
            models.length > 0 &&
            filteredModels.map((model) => {
              return (
                <ModelOverviewWidget
                  model={model}
                  updateLikedModels={updateLikedModels}
                  likedModels={likedModels}
                  key={model.id.toString() + `-${model.type}`}
                />
              );
            })}
        </motion.div>
      </div>
    </>
  );
}
