import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface SelectMenuProps {
  values: { id: number; value: string }[];
  label: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

export default function SelectMenu({
  values,
  label,
  onChange,
}: SelectMenuProps) {
  const [selected, setSelected] = useState(
    values[0] ? values[0] : { id: 0, value: "" }
  );

  useEffect(() => {
    onChange(selected.value);
  }, [selected, onChange]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-500">
            {label}
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative bg-accent w-full cursor-default rounded-md py-1 pl-1 pr-10 text-left shadow-sm focus:border-white focus:outline-none focus:ring-1 focus:ring-white ">
              <span className="flex items-center">
                <span className="ml-3 block truncate text-white">
                  {selected.value}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-accent py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {values.map((val) => (
                  <Listbox.Option
                    key={val.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-blue-500 bg-blue-100" : "text-white",
                        "relative cursor-default select-none py-2 pl-1 pr-9"
                      )
                    }
                    value={val}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {val.value}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-blue-600" : "text-white",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
