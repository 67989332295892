import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Model } from "./Models";
import Link from "next/link";
import {
  faBath,
  faBedFront,
  faStairs,
  faPuzzlePiece,
  faRulerCombined,
} from "@fortawesome/pro-solid-svg-icons";

interface SlideProps {
  model: Model;
}
const Slide = ({ model }: SlideProps) => {
  return (
    <Link
      href={{
        pathname: `/design/${encodeURIComponent(model.slug)}`,
      }}
    >
      <div className="w-full h-full">
        <Image
          src={process.env.NEXT_PUBLIC_AWS_CDN_URL + model.images[0]}
          alt="Dvele featured home"
          width={500}
          height={200}
          className="mb-4 featured-home-img w-full"
          placeholder="blur"
          blurDataURL="../public/assets/StockHome.png"
        />
        {model.floorplans && (
          <Image
            src={process.env.NEXT_PUBLIC_AWS_CDN_URL + model.floorplans[0]}
            alt="Dvele featured home"
            width={500}
            height={200}
            className="mb-4 featured-home-img w-full"
            placeholder="blur"
            blurDataURL="../public/assets/StockHome.png"
          />
        )}
        <span className="card-title">{model.name}</span>
        <div className="flex opacity-70 items-center flex-wrap items-baseline">
          <FontAwesomeIcon
            icon={faStairs}
            style={{ fontSize: 14, marginRight: 4 }}
          />
          <p>{model.stories}</p>
          <span className="px-2">|</span>
          <FontAwesomeIcon
            icon={faBedFront}
            style={{ fontSize: 14, marginRight: 4 }}
          />
          <p>{model.bedrooms}</p>
          <span className="px-2">|</span>
          <FontAwesomeIcon
            icon={faBath}
            style={{ fontSize: 14, marginRight: 4 }}
          />
          <p>{model.bathrooms}</p>
        </div>
        <div className="flex opacity-70 items-center flex-wrap items-baseline">
          <FontAwesomeIcon
            icon={faRulerCombined}
            style={{ fontSize: 14, marginRight: 4 }}
          />
          <p>{model.footprint}</p>
          <span className="px-2">|</span>
          <FontAwesomeIcon
            icon={faPuzzlePiece}
            style={{ fontSize: 14, marginRight: 4 }}
          />
          <p>{model.num_modules}</p>
          <span className="px-2">|</span>
          <p>{model.sqft} sq. ft.</p>
        </div>
        <p>
          Starting at $
          {new Intl.NumberFormat("en-US", {
            maximumSignificantDigits: 4,
          }).format(model.base_price)}
        </p>
      </div>
    </Link>
  );
};

interface LandingSectionProps {
  featuredModels: Model[];
}
export default function LandingSection({
  featuredModels,
}: LandingSectionProps) {
  return (
    <>
      <div className="landing-sec-bg p-10 pt-24">
        <div className="flex justify-between h-full">
          <div className=" h-full flex items-end pt-4">
            <h1 className="landing-title">
              Dvele
              <br />
              Design Studio
            </h1>
          </div>
          <div
            className={`showcase-card hidden lg:block ${
              featuredModels.length > 0 && "fade-in"
            }`}
          >
            <Swiper
              modules={[Pagination, Autoplay]}
              // direction="vertical"
              spaceBetween={40}
              slidesPerView={1}
              speed={1000}
              autoplay={{ delay: 10000 }}
              //navigation

              loop={true}
              pagination={{ clickable: true }}
              className="h-full"
            >
              {featuredModels.map((model, i) => {
                return (
                  <SwiperSlide key={i}>
                    <Slide model={model} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
