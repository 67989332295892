import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons";
import React, { useContext } from "react";
import { Model } from "./Models";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { LoginContext, UserContext } from "./Layout";
import * as Sentry from "@sentry/nextjs";

async function sendData(
  uuid: string,
  modelId: number,
  userId: any,
  liked: boolean
) {
  var data = {
    uuid: uuid,
    model_id: modelId,
    user_id: userId,
    action: "create",
  };
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    "x-api-key": process.env.NEXT_PUBLIC_AWS_API_KEY!,
  };

  try {
    if (liked == false) {
      var response = await fetch(
        process.env.NEXT_PUBLIC_AWS_BASE_URL + "likes",
        {
          method: "POST",
          headers,
          body: JSON.stringify(data),
        }
      );
    } else {
      data.action = "delete";
      var response = await fetch(
        process.env.NEXT_PUBLIC_AWS_BASE_URL + "likes",
        {
          method: "DELETE",
          headers,
          body: JSON.stringify(data),
        }
      );
    }

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    console.log("Data sent successfully:", result);
  } catch (error) {
    console.error("Error sending data:", error);
    if (error instanceof Error) {
    Sentry.captureException("Error sending like request: "+error.message)
    }
  }
}

interface LikeProps {
  model: Model;
  updateLikedModels: (newLikedModels: number[]) => void;
  // setLikedModels: React.Dispatch<React.SetStateAction<number[]>>;
  likedModels: number[];
}

export default function LikeButton({
  likedModels,
  model,
  updateLikedModels,
}: LikeProps) {
  var liked = likedModels.includes(model.id);
  // console.log("likedmodels, ", likedModels, "like ", likedModels.includes(model.id));

  const userUUID = useContext(UserContext);
  const loggedInUser = useContext(LoginContext);

  const handleLikeClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (liked) {
      const newLikedModels = likedModels.filter(
        (likedModel) => likedModel !== model.id
      );
      localStorage.setItem("likedModels", JSON.stringify(newLikedModels));
      updateLikedModels(newLikedModels);
    } else {
      const newLikedModels = [...likedModels, model.id];
      localStorage.setItem("likedModels", JSON.stringify(newLikedModels));
      updateLikedModels(newLikedModels);
    }

    if (loggedInUser) {
      sendData(userUUID, model.id, loggedInUser, liked);
    } else {
      sendData(userUUID, model.id, null, liked);
    }
    liked = !liked;
  };

  return (
    <button onClick={handleLikeClick} className="m-0 p-0">
      <FontAwesomeIcon
        icon={liked ? (faHeartSolid as IconProp) : (faHeart as IconProp)}
        color={liked ? "red" : "gray"}
      />
    </button>
  );
}
